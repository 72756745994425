.content {
    height: 100vh;
    background: #F0FAFE;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.Book {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 535px;
}

.Book > svg {
    width: 370px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.Book > button {
    color: #1F4F69;
    font-family: Raleway, sans-serif;
    padding: 11px 38px;
    font-size: 15px;

    bottom: 25%;
    position: absolute;
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
}

.Book > button:active, .Book > button:hover  {
    background: #242E6A;
}

@media screen and (max-width: 900px) {
    .Book > svg {
        width: 300px;
    }

    .Book > button {
        bottom: 28%;
    }
}