.Button {
    user-select: none;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: #FFF;
    color: #1F4F69;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    font-size: 25px;
    border-radius: 10px;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    outline: none;
}

.Button:not(.Button--mode-gray):hover {
    cursor: pointer;
    background: #2D81AD;
    color: #fff;
}

.Button--mode-gray:not(.Button--disabled) {
    cursor: pointer;
}

.Button--size-m {
    padding: 15px 50px;
}

.Button--size-l {
    padding: 0 15%;
    height: 80px;
}

.Button--size-s {
    padding: 14px 32px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.Button--disabled {
    background: #C7E7F6;
}

.Button--active {
    background: #2D81AD;
    color: #fff;
}

.Button--stretched {
    width: 100%;
}

.Button--disabled.Button:hover {
    background: #C7E7F6;
    color: #1F4F69;
}

.Button--mode-gray {
    background: #D1D3D4;
    color: #000;
    border: 1px solid #000000;
    border-radius: 25px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    height: 60px;
    padding: 0 10px;
}

.Button--mode-gray:not(.Button--disabled):active {
    background: #EE3326;
}


@media (max-width: 425px) {
    .Button--size-m {
        font-size: 20px;
    }
    .Button--size-m {
        padding: 15px 26px;
    }

    .Button--size-l {
        margin: 0 50px;
        font-size: 18px;
    }

    .Button--size-s {
        padding: 8px 25px;
    }
}

@media (max-width: 300px) {
    .Button--size-l {
        margin: 0 50px;
        font-size: 12px;
    }
}