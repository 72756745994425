.Button {
  padding: 8px 20px;
  display: flex;
  gap: 10px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #1F4F69;
  border: 1px solid #1F4F69;
  border-radius: 10px;
}

.Button:hover {
  cursor: pointer;
  background: #2D81AD;
  border-color: #2D81AD;
  color: #fff;
}

.Button:hover svg {
  fill: #fff !important;
}