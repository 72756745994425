@font-face {
    font-family: Raleway;
    font-display: fallback;
    src: url('./Raleway-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Raleway;
    font-display: fallback;
    src: url('./Raleway-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: Raleway;
    font-display: fallback;
    src: url('./Raleway-Bold.ttf');
    font-weight: 700;
}