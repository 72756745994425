.Mask_Line_First {
    margin-top: -60px;
    margin-left: -90px;
}

.Mask_Line_Odd {
    margin-top: -30px;
    margin-left: -90px;
}

.Mask_Line_Even {
    margin-top: -20px;
    margin-left: -216px;
}

.Mask_Line3 {
    margin-top: -65px;
    margin-left: -70px;
}

.Mask_Line4 {
    margin-top: -65px;
    margin-left: -20px;
}

.Mask_Line5 {
    margin-top: -65px;
    margin-left: -70px;
}

.Mask_Line6 {
    margin-top: -65px;
    margin-left: -20px;
}

.Level_Page {
    background: #AFC2F1;
}

.Level_Lines > div {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.Level_Lines > div > div > svg:hover {
    cursor: pointer;
}

@media screen and (max-width: 999px) {

    .Mask_Line_First {
        width: 760px;
    }

    .Mask_Line_Odd {
        width: 755px;
    }

    .Mask_Line_Even {
        width: 900px;
    }
    /*.Level_Page {*/
    /*    max-width: 760px;*/
    /*}*/

    /*.Level_Lines {*/
    /*    max-width: 760px;*/
    /*}*/

    /*.Level_Lines > div {*/
    /*    width: 1020px;*/
    /*}*/
}
