.StartPage_Placeholder_action {
    width: 100%;
}

.StartPage_Placeholder_action > button {
    margin: 0;
    width: 100%;
}

.StartPage_Placeholder_action > button:hover, .StartPage_Placeholder_action > button:active {
    background: #EE3326;
}

.StartPage_Placeholder_action > button:first-child {
    margin-bottom: 20px;
}

.StartPage_Placeholder {

}

.StartPage_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Placeholder_icon {
    margin-bottom: 20px;
    margin-left: 10px;
    width: 100%;
    height: auto;
    max-width: 260px;
}

@media screen and (max-width: 400px) {
    .StartPage_Placeholder > div:has(h2) {
        width: 95%;
    }

    .StartPage_Placeholder > div:has(h1) {
        margin-top: -30px;
    }

    .Placeholder_icon {
        max-width: 250px;
    }
}

@media screen and (max-width: 350px) {
    .Placeholder_icon {
        max-width: 220px;
    }
}