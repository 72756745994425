.Hint_Vertical {
    width: 10%;
    height: 10%;
}

.Hint_Horizontal {
    transform: rotate(-90deg);
    margin-bottom: -20px;
}

.Hint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto !important;
}

.Hint_Placeholder {
    width: 165px;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    color: #3D64AF;
    margin-left: 10px;
}