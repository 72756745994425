.content {
    height: 100vh;
    background: #F0FAFE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Expanded_Book {
    position: relative;
    display: flex;
    width: 914px;
    height: 615px;
    justify-content: space-between;
}

.Book_Paragraph {
    /*position: relative;*/
    /*z-index: -1;*/
    width: 100%;
    margin-top: -20px;
    margin-bottom: 5px;
    font-family: Arsenal;
    font-style: italic;
    font-size: 18px;
    color: #1F4F69;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 480px;
    word-break: break-word;
    /*---*/
    white-space: pre-line;
}

.Book_Paragraph::-webkit-scrollbar,
.Book_Paragraph::-webkit-scrollbar-thumb,
.Book_Paragraph::-webkit-scrollbar-track {
    border: none;
    background: transparent;
    opacity: 0;
}

.Book_Paragraph::-webkit-scrollbar-button,
.Book_Paragraph::-webkit-scrollbar-track-piece,
.Book_Paragraph::-webkit-scrollbar-corner,
.Book_Paragraph::-webkit-resizer {
    display: none;
}

.Book_Paragraph::-webkit-scrollbar-thumb {
    display: none;
    opacity: 0;
}

.Book_Paragraph::-webkit-scrollbar-track {
    display: none;
    opacity: 0;
}

.Custom_Scrollbar {
    position: absolute;
    top: 30px;
    right: 80px;
    z-index: 10;
    background-image: url("https://i.imgur.com/CxTd9we.png");
    background-repeat: repeat-y;
    background-position: center;
    border-radius: 10px;
    height: 75%;
    width: 20px;
}

.Custom_Thumb {
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 999;
    background-image: url("https://i.imgur.com/62PuXVg.png");
    background-repeat: no-repeat no-repeat;
}

.Custom_Thumb:hover {
    cursor: pointer;
}

.Book_Overflow_Hidden {
    overflow: hidden;
}

.Book_Illustration {
    user-select: none;
    width: 40%;
    margin-top: 100px !important;
}
.Book_Illustration > img {
    max-width: 320px;
}

.ml_80 {
    margin-left: 80px;
}

.Arrow {
    user-select: none;
    position: absolute;
    top: 42%;
}

.Left {
    left: 12px;
}

.Right {
    right: 12px;
}

.Arrow:hover > circle {
    fill: #242E6A;
}

.Arrow:hover > path {
    fill: #fff;
}

.Book_Paragraph > h1 {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
}

.Buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.Linear_Gradient {
    z-index: 0;
    position: absolute;
    width: 35%;
    height: 40px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.Desktop {
    justify-content: center;
}

.Desktop > div:nth-child(1) {
    margin-right: 75px;
}

@media screen and (max-width: 900px) {

    .Buttons {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
    }

    .Buttons > div {
        margin: auto auto 10px auto;
    }

    .content {
        align-items: flex-start;
    }

    .Expanded_Book {
        margin-top: -180px;
        transform: scale(0.7);
    }
}



@media screen and (max-height: 700px) {
    .Expanded_Book {
        margin-top: 0;
    }
}