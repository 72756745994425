.SelectLevel_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Placeholder_element {
    position: relative;
    height: 100%;
    justify-content: center;
}

.Placeholder_element > div:has(h2) {
    width: 90%;
}

.Placeholder_element > div:nth-child(3) > h2 > b {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
}

.Placeholder_element > div > button {
    margin: 0;
}

.Placeholder_icon {
    display: flex;
    align-items: center;
    min-height: 245px;
}

.Placeholder_action {
    width: 300px;
}

.Button_element {
    width: 100%;
}

.Button_element--level-1:active,
.Button_element--level-2:active,
.Button_element--level-3:active,
.Button_element--level-4:active,
.Button_element--level-5:active {
    background: initial !important;
}

.Button_element--level-1:hover {
    background: #4086C3
}

.Button_element--level-1 {
    background: #6CA8DC;
}

.Button_element--level-2:hover {
    background: #F57970
}

.Button_element--level-2 {
    background: #FF9E97;
}

.Button_element--level-3:hover {
    background: #818285
}


.Button_element--level-3 {
    background: #A8A9AC;
}

.Button_element--level-4:hover {
    background: #627FC7;
}

.Button_element--level-4 {
    background: #829AD6;
}

.Button_element--level-5:hover {
    background: #C86853;
}

.Button_element--level-5 {
    background: #D68776;
}


.Placeholder--level-1 {
    background: #B1D5F5;
}
.Placeholder--level-2 {
    background: #FFCFCC;
}
.Placeholder--level-3 {
    background: #D1D3D4;
}
.Placeholder--level-4 {
    background: #AFC2F1;
}
.Placeholder--level-5 {
    background: #DDA295;
}


.SelectLevel_Wrapper {
    background: #fff;
}

.Level_element {
    margin-top: 25px;
}

@media screen and (max-width: 999px) {
    .Placeholder_element > div:has(h2) {
        width: 80%;
        min-height: 150px;
    }

    .Level_element {
        margin-top: 50px;
    }

    /*.Placeholder_element > div:has(button) {*/
    /*    position: absolute;*/
    /*    bottom: 30px;*/
    /*}*/
}