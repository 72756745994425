.Card__front {
  background: #2d81ad;
  border-radius: 10px;
  padding-top: 60px;
  width: 219px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Card__front:hover {
  cursor: pointer;
}

.Card__back {
  background: #fff;
  border-radius: 10px;
  width: 219px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 12px;
}

.Card__back:hover {
  cursor: pointer;
}

.Card__description {
  font-family: Raleway, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #1F4F69;
}

.Img__wrapper {
  width: 182.5px;
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Title__wrapper {
  width: 200px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Card__title {
  color: #fff;
  font-family: Raleway, sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.Card__img {
  max-height: 137px;
}