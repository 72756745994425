.Level_Page {
    overflow: hidden;
    position: relative;
}

.Page_Wrapper {
    background: #fff !important;
}

.Level_Page > svg > g > rect:hover {
    cursor: pointer;
}

.Level_Arrows_Wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Level_Arrows {
    position: fixed;
    bottom: 30px;
}

@media screen and (max-width: 999px) {
    .Level_Page {
        touch-action: pan-x;
        overflow-x: auto;
    }

    .Level_Page > svg {
        height: 100vh;
        width: calc(100vh + 80px);
    }
}