.Text-block {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 139px;
}

.Background {
  height: 100%;
  background-image: url("../../img/desktop_background.png");
  background-position: center 24%;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 935px;
  padding: 0 15px;
  background-repeat: no-repeat;
}

.Page__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 425px) {
  .Background {
    background-image: url("../../img/mobile_background.png");
    background-position: center 0%;
  }
  .Text-block {
    margin-top: 148px;
  }
}

@media (max-width: 320px) {
  .Text-block {
    margin-top: 41px;
  }
  .Background {
    background-position: center 71%;
    background-size: cover;
  }
}
