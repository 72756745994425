.GameBasePage_content {
    padding: env(safe-area-inset);
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 25px;
    width: 700px;
    height: 667px;
}

.GameBasePage_wrapper {
    position: relative;
    background: #D1D3D4;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Game_Arrow {
    position: absolute;
}


.Game_Arrow:not(.Arrow--disabled) > path:first-child:hover {
    fill: #EE3326;
}

.Game_Arrow:not(.Arrow--disabled):hover {
    cursor: pointer;
}

.Arrow--disabled {
    opacity: 0.1;
}

.Arrow_left {
    left: 30px;
}
.Arrow_right {
    right: 30px;
}

@media screen and (max-width: 999px) {
    .GameBasePage_content {
        border: none;
        border-radius: 0;
        width: 100%;
        height: 100vh;
    }
}