@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input,
textarea,
button,
select,
svg,
img,
a {
    -webkit-tap-highlight-color: transparent;
}

body {
    background: #A3CFE5;
    overflow: auto;
}

.Modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
}

.Modal {
    position: absolute;
    display: inline-table;
    width: 40%;
    min-height: 200px;
    margin: auto;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    transition: height 500ms;
    overflow: hidden;
    /* Белый */

    background: #FFFFFF;
    /* Черный */

    border: 1px solid #000000;
    border-radius: 25px;
}

.GameArrow_Mobile {
    z-index: -1;
}

.GameArrow_Mobile:hover {
    cursor: pointer;
}

.GameArrow_Mobile:active  > path:first-child {
    fill: #EE3326;
}



.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 800ms, transform 800ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 800ms, transform 800ms;
}

/*---*/

.fade-mobile-enter {
    opacity: 0;
}

.fade-mobile-enter-active {
    opacity: 1;
    transition: opacity 800ms;
}

.fade-mobile-exit {
    opacity: 1;
}

.fade-mobile-exit-active {
    opacity: 0;
    transition: opacity 800ms;
}

.react-card-flip {
    position: relative !important;
}


#scrollbar {
    max-width: 350px;
    max-height: 500px;
    margin: 20px 40px 0 0;
}

.clickable {
    cursor: pointer;
}

.Game_Second_Level:hover {
    cursor: pointer;
}

@media screen and (max-width: 999px) {
    .Modal {
        width: 80%;
    }
}