.Placeholder_element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Placeholder_description {
    width: 70%;
}

.Placeholder_description > h2 {
    margin-top: 9px;
    white-space: pre-line;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.Placeholder_title {
    width: 80%;
}

.Placeholder_title > h1 {
    margin-top: 30px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}

.Placeholder_action {
    margin-top: 30px;
    width: 44%;
}

@media screen and (max-width: 999px) {
    .Placeholder_description {
        width: 80%;
    }

    .Placeholder_action {
        width: 75%;
    }
}