.Page__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Page__background {
  height: 100%;
  background-image: url("../../img/desktop_choice_background.png");
  background-position: center 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
}

.Content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 0;
}

.Heading {
  text-align: center;
  font-size: 35px;
  margin-bottom: 45px;
}

.Error {
  max-width: 580px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 33px;
  color: #2D81AD;

}

.Buttons__container {
  display: flex;
  gap: 14px;
  margin: 0 auto;
  margin-bottom: 18px;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 20px;
}

.Buttons__container::-webkit-scrollbar {
  display: none;
}

.Buttons__container > button:first-child {
  margin-left: auto;
}

.Buttons__container > button:last-child {
  margin-right: auto;
}

@media (max-width: 532px) {
  .Page__background {
    background-image: url("../../img/mobile_choice_background.png");
    background-position: center 20%;
  }
  .Content {
    padding: 54px 0;
  }
  .Heading {
    font-size: 25px;
    max-width: 330px;
  }
  .Error {
    margin-top: 47px;
    max-width: 345px;
  }
}

@media (max-width: 320px) {
  .Content {
    padding: 30px 0;
  }
  .Heading {
    font-size: 20px;
    max-width: 290px;
  }
}
