.MenuSelectionPage_content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MenuSelectionPage_content > svg {
    margin: 50px 0;
    border-radius: 13px;
}

.MenuSelectionPage_cards {
    width: 100%;
    flex: 1;
    display: flex;
    gap: 25px;
    justify-content: space-around;
}

.NewYearCard_content {
    background: #3D64AF;
}

.NewYearCard_content > h1 {
    color: #fff;
    padding: 20px 0 14px 0;
}

.NewYearCard_content > button {
    color: #3D64AF;
}

.Merchandise_content {
    background: #E08207
}

.Merchandise_content > h1 {
    color: #fff;
}

.Merchandise_content > button {
    color: #E08207;
}


.content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Buttons {
    z-index: 2;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.Buttons > button {
    margin-top: 55px;
}

.Third_Sign > div > div {
    width: 60% !important;
}

.Sign {
    z-index: -1;
    max-width: 40px;
    max-height: 400px;
    position: absolute;
    bottom: 0;
    left: 49%;
}

@media screen and (max-width: 400px) {
    .Third_Sign > div > div {
        width: 50% !important;
    }
}