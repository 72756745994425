.Modal_Text > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #000;
}

.GameFinish_Modal {
    padding: 30px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Modal_Actions {
    width: 90%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.Modal_Actions > button:hover, .Modal_Actions > button:active {
    background: #EE3326;
}

.Modal_Actions > button:first-child {
    margin-bottom: 20px;
}

.Modal_Actions > button {
    font-size: 19px;
    width: 100%;
    margin: 0;
}
