:root {
  --margin: calc(0px - 50vw + 108px);
}

.Page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding-bottom: 20px;
}

.Content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 0;
}

.Heading {
  text-align: center;
  font-size: 35px;
  margin-bottom: 11px;
}

.Slider__wrapper {
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: auto;
  gap: 20px;
  padding: 30px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Slider__wrapper::-webkit-scrollbar {
  display: none;
}

.Slider__content {
  position: relative;
  overflow-x: auto;
  width: 100%;
  max-width: 700px;
}

.Page__description {
  max-width: 500px;
  margin-top: 22px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2D81AD;
}

.Button__wrapper {
  padding: 0 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Share-button__container {
  margin: 30px 0;
  padding-bottom: 30px;
  display: flex;
  gap: 36px;
  align-items: center;
}

@media (max-width: 799px) {
  .Heading {
    margin-bottom: 22px;
  }

  .Slider__content {
    justify-content: flex-start;
  }

  .Button {
    width: 100%;
  }

  .Share-button__container {
    flex-direction: column;
    gap: 15px;
  }

  .Slider__wrapper>div:nth-child(1) {
    margin-left: calc(50vw - 108px);
  }

  .Slider__wrapper>div:nth-child(3)::after {
    content: '';
    position: absolute;
    right: var(--margin);
    width: calc(50vw - 108px);
    height: 100px;
    z-index: 1001;
    top: 0;
    background: rgba(0, 0, 0, 0);
  }
}