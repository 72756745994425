.MenuCard_content {
    padding: 25px;
    max-height: 454px;
    width: 30%;
    min-width: 285px;
    background: #A3CFE5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.MenuCard_content > img {
    margin: auto;
    width: auto;
    max-width: 243px;
}

.MenuCard_content > h1 {
    padding: 21px 0 40px 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #1F4F69;
}

.MenuCard_content > button {
    height: 48px;
    min-height: 48px;
    font-size: 22px;
}