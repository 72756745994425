.Modal_Text > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.Share_Modal {
    padding: 30px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Modal_Actions {
    width: 90%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.Modal_Actions > button:first-child {
    margin-bottom: 20px;
}

.Modal_Actions > button {
    width: 100%;
    margin: 0;
}

.Modal_Actions > button:hover, .Modal_Actions > button:active {
    background: #EE3326;
}