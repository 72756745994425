.Hint_Modal {
    padding: 27px 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Hint_Text {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.Hint_Text > li {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000;
}

.Hint_Action {
    width: 100%;
    margin-top: 20px;
}

.Hint_Action > button {
    margin: 0;
    width: 100%;
}

.Hint_Action > button:hover {
    background: #EE3326;
}

@media screen and (max-width: 999px) {
    .Hint_Action > button {
        height: 45px;
    }
}