.Result_Page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Result_Page_Wrapper {
    background: #fff;
}

.Level_element {
    margin-top: 25px;
}

.Stars_Element {
    margin-top: -20px;
}

.Page_Text {
    margin-top: 35px;
}

.Page_Description {
    width: 100%;
    margin: auto;
    padding: 10px 0;
}

.Page_Description > h2 {

    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000;
}

.Page_Title > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.Page_Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.Page_Actions {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 30px;
}

.Page_Actions > button {
    width: 100%;
    margin: auto;
}

.Page_Actions > button:first-child, .Page_Actions > button:nth-child(2) {
    margin-bottom: 20px;
}

.Retry_Icon {
    width: auto !important;
    padding: 0 25px;
}

.Button_element--level-1:active,
.Button_element--level-2:active,
.Button_element--level-3:active,
.Button_element--level-4:active,
.Button_element--level-5:active {
    /*background: inherit !important;*/
}

.Button_element--level-1:hover {
    background: #4086C3;
}

.Button_element--level-1:active {
    background: #4086C3 !important;
}
.Button_element--level-1 {
    background: #6CA8DC;
}

.Button_element--level-2:hover {
    background: #F57970
}

.Button_element--level-2:active {
    background: #F57970 !important;
}
.Button_element--level-2 {
    background: #FF9E97;
}

.Button_element--level-3:hover {
    background: #818285
}


.Button_element--level-3:active {
    background: #818285 !important;
}
.Button_element--level-3 {
    background: #A8A9AC;
}

.Button_element--level-4:hover {
    background: #627FC7;
}

.Button_element--level-4:active {
    background: #627FC7 !important;
}
.Button_element--level-4 {
    background: #829AD6;
}

.Button_element--level-5:hover {
    background: #C86853;
}

.Button_element--level-5:active {
    background: #C86853 !important;
}
.Button_element--level-5 {
    background: #D68776;
}


@media screen and (max-width: 999px) {
    .Result_Page_Wrapper {
        background: none;
    }

    .Result_Page {
        height: 100vh;
    }

    .Page_Description {
        width: 95%;
    }

    .Page_Actions {

        width: 82%;
    }

    .Level_element {
        margin-top: 50px;
    }
}