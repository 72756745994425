.Pen_Line1 {
    margin-top: -80px;
    /*margin-top: 115px;*/
    margin-left: -150px;
}

.Level_Page {
    background: #DDA295;
}

.Pen_Line2 {
    /*margin-top: 70px;*/
    margin-left: -290px;
}

.Pen_Line2 > div {
    height: 110px;
    margin-top: 75px;
}



.Pen_Lines > div {
    z-index: -1;
    /*width: 1000px;*/
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.Pen_Lines > div > div {
    margin-right: -160px;
}

.Pen_Line1 > div {
    height: 110px;
    margin-top: 40px;
}


.Pen_Lines > div > div > svg {
    height: 30px;
    margin-right: -40px;
    transform: rotate(325deg);
}

.Pen_Lines > div > div > svg:hover {
    cursor: pointer;
}


@media screen and (max-width: 999px) {
    .Pen_Lines {
        width: 700px;
    }
}