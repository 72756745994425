.Level_title > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    text-transform: uppercase;
}

.Level_title {
    text-align: center;
}

.Level_element {
    width: 180px;
}

.Level_Counters {
    display: flex;
    margin-top: 10px;
}

.Count_element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 60px;
}

.Count_element > span {
    margin-top: -2px;
}

.Count_element:first-child {
    border-radius: 25px 0 0 25px;
    background: #fff;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.Count_element:last-child {
    border-radius: 0 25px 25px 0;
    border: 1px solid #000;
}


.Count_element > span {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}