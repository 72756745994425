.Heading {
    user-select: none;
    color: #1F4F69;
    font-family: Raleway, sans-serif;
    font-size: 45px;
    font-weight: bold;
}

@media (max-width: 425px) {
    .Heading {
        font-size: 35px;
    }
}

@media (max-width: 320px) {
    .Heading {
        font-size: 30px;
    }
}