.Share_Button {
    background: #FFFFFF;
    padding: 15px;
    max-height: 40px;
    min-width: 200px;
    border: 1px solid #242E6A;
    border-radius: 10px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #242E6A;
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.Share_Button > svg {
    margin-right: 10px;
}

.Share_Button:hover {
    cursor: pointer;
}

.Share_Button:hover {
    background: #242E6A;
    border: 1px solid #fff;
}

.Share_Button:hover > span {
    color: #fff;
}

.Share_Button:hover > svg > path {
    fill: #fff;
}


@media screen and (max-width: 900px) {
    .Share_Button {
        font-size: 14px;
    }
}