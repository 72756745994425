.PreloadPage_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PreloaderPage_lottie {
    width: 83px;
}