.Typography {
    font-family: Raleway, sans-serif;
    color: #1F4F69;
    font-weight: 400;
    font-size: 23px;
    user-select: none;
}

@media (max-width: 425px) {
    .Typography {
        font-size: 20px;
    }
}

@media (max-width: 320px) {
    .Typography {
        font-size: 16px;
    }
}