.Sign_Button {
    background-color: #3D64AF;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    font-size: 25px;
    border-radius: 25px;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    color: #fff;
    padding: 10px;
    text-align: center;
    box-shadow: 4px -3px 0 3px #2C4E67;
    margin: 10px 15px;
    user-select: none;
}

.Sign_Button > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 5px solid #ABCDE2;
    border-radius: 25px;
    padding: 10px 0;
}

.Sign_Button > div > img {
    width: 15%;
    height: 15%;
    margin: 0 5px;
}

.Sign_Button > div > div {
    width: 50%;
}

.Left_Arrow {
    transform: rotate(180deg);
}

.Sign_Button:hover {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .Sign_Button > div > img {
        width: 20%;
        height: 20%;
    }

    .Sign_Button {
        font-size: 20px;
    }

    .Sign_Button > div {
        padding: 5px 0;
    }
}

@media screen and (max-width: 400px) {
    .Sign_Button {
        font-size: 16px;
    }

}