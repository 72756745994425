.Level_Page {
    background: #D1D3D4;
}

.Cup_Line1 {
    margin-top: -115px;
    margin-left: -70px;
}

.Cup_Line2 {
    margin-top: -65px;
    margin-left: -20px;
}

.Cup_Line3 {
    margin-top: -65px;
    margin-left: -70px;
}

.Cup_Line4 {
    margin-top: -65px;
    margin-left: -20px;
}

.Cup_Line5 {
    margin-top: -65px;
    margin-left: -70px;
}

.Cup_Line6 {
    margin-top: -65px;
    margin-left: -20px;
}

.Level_Lines > div > div > svg:hover {
    cursor: pointer;
}

.Level_Lines > div {
    /*width: 760px;*/
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

@media screen and (max-width: 999px) {
    .Cup_Line1, .Cup_Line2, .Cup_Line4, .Cup_Line6 {
        width: 700px;
    }

    .Cup_Line3, .Cup_Line5 {
        width: 750px;
    }
}