.Result_Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

body {
    background: #fff;
}

.Points_Title {
    padding: 75px 0 25px 0;
}

.Points_Title > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #000000;
}

.Page_Content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Page_Text {
    width: 90%;
    padding-top: 20px;
}

.Page_Text > h1 {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.Page_Text > h2 {
    padding: 10px 0;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    min-height: 240px;
    white-space: pre-line;
}

.Page_Actions {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Page_IconButtons > button {
    padding: 0 30px;
}

.Page_IconButtons > button > svg {
    border: none;
}


.Page_IconButtons > button:hover, .Page_IconButtons > button:active {
    background: #EE3326;
}

.Page_IconButtons {
    padding: 10px 0 20px 0;
    display: flex;
    gap: 20px;
}

.Page_Stretched_Buttons {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.Page_Stretched_Buttons > a {
    width: 100%;
}

.Page_Stretched_Buttons > button, .Page_Stretched_Buttons > a > button {
    padding: 0 10px;
    margin: auto;
    width: 100%;
}

.Page_Stretched_Buttons > button:hover, .Page_Stretched_Buttons > button:active, .Page_Stretched_Buttons > a > button:hover {
    background: #EE3326;
}



@media screen and (max-width: 999px) {
    .Page_Text > h2 {
        min-height: 130px;
    }

    .Page_Stretched_Buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 370px) {
    .Page_Text > h2 {
        font-size: 13px;
        line-height: 16px;
    }
}

@media screen and (max-width: 300px) {
    .Page_Text > h1 {
        font-size: 17px;
        line-height: 20px;
    }

    .Page_Text > h2 {
        font-size: 11px;
        line-height: 14px;
    }
}

@media screen and (max-height: 680px) {

    .Page_Text > h2 {
        font-size: 14px;
        line-height: 17px;
    }
}