.Level_Page {
    background: #B1D5F5;
}

.Level_Line_First {
    margin-top: -130px;
    margin-left: -90px;
}

.Level_Line_Odd {
    margin-top: -75px;
    margin-left: -90px;
}


.Level_Line_Even {
    margin-top: -75px;
    margin-left: -40px;

}

.Level_Lines > div > div {
    margin-right: 8px;
}

.Level_Lines > div {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.Level_Lines > div > div > svg:hover {
    cursor: pointer;
}

@media screen and (max-width: 999px) {
    .Level_Line_Odd {
        width: 800px;
    }
    .Level_Line_Even {
        width: 748px;
    }
    .Level_Line_First {
        width: 800px;
    }
}